import { CheckCircleFilled } from '@ant-design/icons';
import { getStaticCDN } from '@design-system/utils/utils';

const CONTENT = {
  background: getStaticCDN('/assets/shared/browse-carousel/carro-certified/bg-ccpo-modal-carro.png'),
  header: {
    name: 'shared.certifiedModal.header.name',
    title: 'shared.certifiedModal.header.title',
  },
  detail: [
    {
      text: 'shared.certifiedModal.detail.inspection',
      icon: {
        desktop: getStaticCDN('/assets/shared/home/ccpo/160.svg'),
        mobile: <CheckCircleFilled />,
      },
    },
    {
      text: 'shared.certifiedModal.detail.guarantee',
      icon: {
        desktop: getStaticCDN('/assets/shared/home/ccpo/5day.svg'),
        mobile: <CheckCircleFilled />,
      },
    },
    {
      text: 'shared.certifiedModal.detail.warranty',
      icon: {
        desktop: getStaticCDN('/assets/shared/home/ccpo/12month.svg'),
        mobile: <CheckCircleFilled />,
      },
    },
    {
      text: 'shared.certifiedModal.detail.noMileage',
      icon: {
        desktop: getStaticCDN('/assets/shared/home/ccpo/couting.svg'),
        mobile: <CheckCircleFilled />,
      },
    },
    {
      text: 'shared.certifiedModal.detail.noMajor',
      icon: {
        desktop: getStaticCDN('/assets/shared/home/ccpo/world.svg'),
        mobile: <CheckCircleFilled />,
      },
    },
  ],
  footer: 'shared.certifiedModal.footer',
};

export default CONTENT;

import { StaticImageData } from 'next/image';
import { TRegionKey } from '@source/interface';
import { Option } from '@source/interface/common';

// Icons
// import ConvertibleIcon from '@design-system/assets/vehicleBodyType/CONVERTIBLE.svg';
// import CoupeIcon from '@design-system/assets/vehicleBodyType/COUPE.svg';
// import EcoCarIcon from '@design-system/assets/vehicleBodyType/Eco-Car.svg';
// import ElectricCarIcon from '@design-system/assets/vehicleBodyType/ElectricCar.svg';
// import VanIcon from '@design-system/assets/vehicleBodyType/Van.svg';
// import PickupIcon from '@design-system/assets/vehicleBodyType/PICKUP.svg';
import HatchbackIcon from '@design-system/assets/vehicleBodyType/hatchback.svg';
import HybridIcon from '@design-system/assets/vehicleBodyType/hybrid.svg';
import LuxurySedanIcon from '@design-system/assets/vehicleBodyType/luxury-sedan.svg';
import MPVIcon from '@design-system/assets/vehicleBodyType/mpv.svg';
import SedanIcon from '@design-system/assets/vehicleBodyType/sedan.svg';
import SportsIcon from '@design-system/assets/vehicleBodyType/sports.svg';
import SUVIcon from '@design-system/assets/vehicleBodyType/suv.svg';
import WagonIcon from '@design-system/assets/vehicleBodyType/wagon.svg';
import BMWIcon from '@design-system/assets/popularBrands/bmw.png';
import HondaIcon from '@design-system/assets/popularBrands/honda.png';
import HyundaiIcon from '@design-system/assets/popularBrands/hyundai.png';
import KiaIcon from '@design-system/assets/popularBrands/kia.png';
import MazdaIcon from '@design-system/assets/popularBrands/mazda.png';
import MercedesBenzIcon from '@design-system/assets/popularBrands/mercedes-benz.png';
import MitsubishiIcon from '@design-system/assets/popularBrands/mitsubishi.png';
import NissanIcon from '@design-system/assets/popularBrands/nissan.png';
import SubaruIcon from '@design-system/assets/popularBrands/subaru.png';
import ToyotaIcon from '@design-system/assets/popularBrands/toyota.png';
import PeroduaIcon from '@design-system/assets/popularBrands/perodua.png';
import ProtonIcon from '@design-system/assets/popularBrands/proton.png';
import VWIcon from '@design-system/assets/popularBrands/vw.png';
import DaihatsuIcon from '@design-system/assets/popularBrands/daihatsu.png';
import SuzukiIcon from '@design-system/assets/popularBrands/suzuki.png';

export const bodyTypeIcons: { [key: string]: StaticImageData } = {
  sedan: SedanIcon,
  mid_sized_sedan: SedanIcon,
  luxury_sedan: LuxurySedanIcon,
  hybrid: HybridIcon,
  hatchback: HatchbackIcon,
  suv: SUVIcon,
  mpv: MPVIcon,
  sports: SportsIcon,
  stationwagon: WagonIcon,
};

export const bodyTypeList: Option<string>[] = [
  {
    label: 'home.filters.options.sedan',
    value: 'sedan',
  },
  {
    label: 'home.filters.options.midSizedSedan',
    value: 'mid_sized_sedan',
  },
  {
    label: 'home.filters.options.luxurySedan',
    value: 'luxury_sedan',
  },
  {
    label: 'home.filters.options.hybrid',
    value: 'hybrid',
  },
  {
    label: 'home.filters.options.hatchback',
    value: 'hatchback',
  },
  {
    label: 'home.filters.options.suv',
    value: 'suv',
  },
  {
    label: 'home.filters.options.mpv',
    value: 'mpv',
  },
  {
    label: 'home.filters.options.sports',
    value: 'sports',
  },
  {
    label: 'home.filters.options.stationwagon',
    value: 'stationwagon',
  },
  {
    label: 'home.filters.options.sportsHatchback',
    value: 'sports_hatchback',
  },
  {
    label: 'home.filters.options.van',
    value: 'van',
  },
  {
    label: 'home.filters.options.pickup',
    value: 'pickup',
  },
  {
    label: 'home.filters.options.electric',
    value: 'electric',
  },
  {
    label: 'home.filters.options.ecoCar',
    value: 'eco_Car',
  },
  {
    label: 'home.filters.options.convertible',
    value: 'convertible',
  },
  {
    label: 'home.filters.options.coupe',
    value: 'coupe',
  },
];

export const brandIcons: { [key: string]: StaticImageData } = {
  BMW: BMWIcon,
  Honda: HondaIcon,
  Hyundai: HyundaiIcon,
  Kia: KiaIcon,
  Mazda: MazdaIcon,
  'Mercedes Benz': MercedesBenzIcon,
  Mitsubishi: MitsubishiIcon,
  Nissan: NissanIcon,
  Subaru: SubaruIcon,
  Toyota: ToyotaIcon,
  Perodua: PeroduaIcon,
  Proton: ProtonIcon,
  Volkswagen: VWIcon,
  Daihatsu: DaihatsuIcon,
  Suzuki: SuzukiIcon,
};

export const HIT_PER_PAGE = 24;
const currentYear = new Date().getFullYear();

export const FILTER_LIST_BY_COUNTRY: Record<TRegionKey, object[]> = {
  sg: [
    {
      label: 'home.quickSearch.SG.below80',
      url: '?priceRange=6888,80000',
    },
    {
      label: 'home.quickSearch.SG.under3Years',
      url: `?year=${currentYear - 3},${currentYear}`,
    },
    {
      label: 'home.quickSearch.SG.suvCars',
      url: '?bodyType=suv',
    },
    {
      label: 'home.quickSearch.SG.midSizedSedan',
      url: '?bodyType=mid_sized_sedan',
    },
    {
      label: 'home.quickSearch.SG.automaticCars',
      url: '?transmission=automatic',
    },
    {
      label: 'home.quickSearch.SG.lowMileage',
      url: '?categories=low_mileage',
    },
  ],
  my: [
    {
      label: 'home.quickSearch.MY.under5Years',
      url: `?year=${currentYear - 5},${currentYear}`,
    },
    {
      label: 'home.quickSearch.MY.suvMPVCars',
      url: '?bodyType=suv,mpv',
    },
    {
      label: 'home.quickSearch.MY.midSizedSedan',
      url: '?bodyType=mid_sized_sedan',
    },
    {
      label: 'home.quickSearch.MY.belowRM50',
      url: '?priceRange=9000,50000',
    },
    {
      label: 'home.quickSearch.MY.belowRM450Month',
      url: '?instalment=5.5,450',
    },
    {
      label: 'home.quickSearch.MY.below50KM',
      url: '?mileage=0,50000',
    },

    // For tukar_je campaign
    // {
    //   label: 'home.quickSearch.MY.tukarJeEcoFriendly',
    //   url: encodeURI(`?v[categories][setting]=category&v[categories][sv]=tukar_je_eco_friendly`),
    // },
    // {
    //   label: 'home.quickSearch.MY.tukarJeFuelEfficiency',
    //   url: encodeURI(`?v[categories][setting]=category&v[categories][sv]=tukar_je_fuel_efficiency`),
    // },
    // {
    //   label: 'home.quickSearch.MY.tukarJeLuxury',
    //   url: encodeURI(`?v[categories][setting]=category&v[categories][sv]=tukar_je_performance`),
    // },
    // {
    //   label: 'home.quickSearch.MY.tukarJeFamily',
    //   url: encodeURI(`?v[categories][setting]=category&v[categories][sv]=tukar_je_family`),
    // },
  ],
  id: [
    {
      label: 'home.quickSearch.ID.under120jt',
      url: '?priceRange=123456,120000000',
    },
    {
      label: 'home.quickSearch.ID.lowKM',
      url: '?mileage=0,10000',
    },
    {
      label: 'home.quickSearch.ID.automatic',
      url: '?transmission=automatic',
    },
    {
      label: 'home.quickSearch.ID.suvMPV',
      url: '?bodyType=suv,mpv',
    },
    {
      label: 'home.quickSearch.ID.hatchback',
      url: '?bodyType=hatchback',
    },
    {
      label: 'home.quickSearch.ID.under3Years',
      url: `?year=${currentYear - 3},${currentYear}`,
    },
  ],
  th: [
    {
      label: 'home.quickSearch.TH.sedan',
      url: '?bodyType=sedan',
    },
    {
      label: 'home.quickSearch.TH.pickup',
      url: '?bodyType=pickup',
    },
    {
      label: 'home.quickSearch.TH.belowB350',
      url: '?priceRange=138889,350000',
    },
    {
      label: 'home.quickSearch.TH.under3Years',
      url: `?year=${currentYear - 3},${currentYear}`,
    },
    {
      label: 'home.quickSearch.TH.below50KM',
      url: '?mileage=0,50000',
    },
    {
      label: 'home.quickSearch.TH.suvHatchback',
      url: '?bodyType=suv,hatchback',
    },
  ],
  // TODO: update this for jp
  jp: [
    { label: 'home.quickSearch.JP.below80', url: '' },
    { label: 'home.quickSearch.JP.under3Years', url: '' },
    { label: 'home.quickSearch.JP.suvCars', url: '' },
    { label: 'home.quickSearch.JP.midSizedSedan', url: '' },
    { label: 'home.quickSearch.JP.automaticCars', url: '' },
    { label: 'home.quickSearch.JP.lowMileage', url: '' },
  ],
  hk: [
    { label: 'home.quickSearch.JP.below80', url: '' },
    { label: 'home.quickSearch.JP.under3Years', url: '' },
    { label: 'home.quickSearch.JP.suvCars', url: '' },
    { label: 'home.quickSearch.JP.midSizedSedan', url: '' },
    { label: 'home.quickSearch.JP.automaticCars', url: '' },
    { label: 'home.quickSearch.JP.lowMileage', url: '' },
  ],
};

export const POPULAR_BRAND_LIST_BY_COUNTRY: Record<TRegionKey, Option<string>[]> = {
  sg: [
    { label: 'Toyota', value: 'Toyota' },
    { label: 'Honda', value: 'HONDA' },
    { label: 'Mercedes Benz', value: 'Mercedes-Benz' },
    { label: 'BMW', value: 'BMW' },
    { label: 'Mazda', value: 'Mazda' },
    { label: 'Hyundai', value: 'Hyundai' },
    { label: 'Mitsubishi', value: 'Mitsubishi' },
  ],
  my: [
    { label: 'Honda', value: 'HONDA' },
    { label: 'Perodua', value: 'PERODUA' },
    { label: 'Proton', value: 'PROTON' },
    { label: 'Toyota', value: 'TOYOTA' },
    { label: 'Nissan', value: 'NISSAN' },
    { label: 'Mazda', value: 'MAZDA' },
    { label: 'Volkswagen', value: 'VOLKSWAGEN' },
    { label: 'Mercedes Benz', value: 'MERCEDES-BENZ' },
  ],
  id: [
    { label: 'Toyota', value: 'TOYOTA' },
    { label: 'Honda', value: 'Honda' },
    { label: 'Suzuki', value: 'SUZUKI' },
    { label: 'Daihatsu', value: 'DAIHATSU' },
    { label: 'Mazda', value: 'MAZDA' },
    { label: 'Nissan', value: 'NISSAN' },
    { label: 'Mitsubishi', value: 'MITSUBISHI' },
  ],
  th: [
    { label: 'Toyota', value: 'TOYOTA' },
    { label: 'Honda', value: 'Honda' },
    { label: 'Mercedes Benz', value: 'MERCEDES-BENZ' },
    { label: 'Mazda', value: 'MAZDA' },
    { label: 'Mitsubishi', value: 'MITSUBISHI' },
    { label: 'BMW', value: 'BMW' },
    { label: 'Hyundai', value: 'HYUNDAI' },
  ],
  // TODO: update this for jp
  jp: [
    { label: 'Toyota', value: 'Toyota' },
    { label: 'Honda', value: 'Honda' },
    { label: 'Mercedes Benz', value: 'Mercedes' },
    { label: 'BMW', value: 'BMW' },
    { label: 'Mazda', value: 'Mazda' },
    { label: 'Hyundai', value: 'Hyundai' },
    { label: 'Mitsubishi', value: 'Mitsubishi' },
  ],
  hk: [
    { label: 'Toyota', value: 'Toyota' },
    { label: 'Honda', value: 'Honda' },
    { label: 'Mercedes Benz', value: 'Mercedes' },
    { label: 'BMW', value: 'BMW' },
    { label: 'Mazda', value: 'Mazda' },
    { label: 'Hyundai', value: 'Hyundai' },
    { label: 'Mitsubishi', value: 'Mitsubishi' },
  ],
};

export const POPULAR_SEARCH_LIST_BY_COUNTRY: Record<TRegionKey, string[]> = {
  sg: ['Honda Vezel', 'Toyota Altis', 'Toyota Wish', 'Honda Jazz', 'Honda Shuttle'],
  my: ['PERODUA MYVI', 'PERODUA AXIA', 'PERODUA BEZZA', 'HONDA CITY', 'PROTON IRIZ'],
  id: ['HONDA BR-V', 'HONDA HRV', 'TOYOTA INNOVA', 'HONDA BRIO', 'TOYOTA AVANZA'],
  th: ['Toyota Vios', 'Honda City', 'Honda Civic', 'Toyota Camry', 'Honda Jazz'],
  // TODO: update this for jp
  jp: ['Honda Vezel', 'Toyota Altis', 'Toyota Wish', 'Honda Jazz', 'Honda Shuttle'],
  hk: ['Honda Vezel', 'Toyota Altis', 'Toyota Wish', 'Honda Jazz', 'Honda Shuttle'],
};

export const ATTRIBUTES_TO_RETRIEVE = [
  'created_at',
  'promo_label',
  'promo_images',
  'promotion',
  'hits',
  'categories',
  'original_price',
  'is_promo',
  'listing.title',
  'listing.slug',
  'listing.mileage',
  'listing.asking_price',
  'listing.photos',
  'listing.current_depreciation',
  'listing.depreciation_display_value',
  'listing.installment',
  'listing.is_certified',
  'listing.is_carro_certified',
  'listing.is_coming_soon',
  'listing.is_pending_sale',
  'listing.is_sold',
  'listing.is_great_buy',
  'listing.is_value_buy',
  'listing.is_agent_serviced',
  'listing.is_agent_warranty',
  'listing.is_carro_anywhere',
  'listing.is_ccpo',
  'listing.is_new_arrival',
  'listing.thumbnail',
  'listing.promotional_price',
  'listing.key_words',
  'listing.months_left',
  'listing.paper_value',
  'listing.status',
  'listing.is_carro_assure',
  'listing.is_carro_assure_plus',
  'listing.has_360_interior_photos',
  'listing.has_360_exterior_photos',
  'listing.maximum_loan_percentage',
  'listing.downpayment_percentage',
  'listing.last_service_date',
  'listing.body_type',
  'inventory.make',
  'inventory.model',
  'inventory.original_registration_date',
  'inventory.number_of_owners',
  'inventory.wheel_drives',
  'inventory.year_of_manufacture',
  'inventory.manufacture_year',
  'inventory.seats',
  'inventory.color',
  'inventory.transmission',
  'inventory.car_type',
  'inventory.fuel_type',
  'inventory.plate_number_odd_even',
  'inventory.road_tax_expiry_date',
  'inventory.tax_expiration_date',
  'inventory.engine_capacity',
  'inventory.fuel_type',
  'inventory.bpkb_available',
  'inventory.owner_type',
  'inventory.coe_expiry_date',
  'location.name',
  'city.name',
  'city.display_name',
  'region.display_name',
  'registration_area.display_name',
];

export const FILTER_CITY_PARAM: Record<TRegionKey, string> = {
  id: 'region.display_name',
  my: 'location.name',
  sg: 'location.name',
  th: 'location.name',
  jp: 'location.name',
  hk: 'location.name',
};

export const COMPANY_NAME: Record<TRegionKey, string> = {
  id: 'Carro Indonesia',
  my: 'Carro',
  sg: 'Carro Singapore',
  th: 'Carro Thailand',
  jp: 'Carro Japan',
  hk: 'Carro HongKong',
};

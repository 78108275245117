import React, { FC } from 'react';
import type { BrowseMoreButtonProps } from '@source/pages/Home/components/BrowseMoreButton/BrowseMoreButtonProps';
import styled from 'styled-components';
import { Spin } from 'antd';
import { useTranslation } from 'react-i18next';

import CustomButton from '@source/components/CustomButton/CustomButton';

const StyledSpinWrapper = styled.div`
  width: 100%;
  margin: 16px 0;
  text-align: center;
`;
const Container = styled.div`
  margin: 40px 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  .ant-btn {
    width: 250px;
    text-transform: capitalize;
    margin-top: 16px;
  }
`;

const TextContainer = styled.div`
  ${(props) => props.theme.typo.familyGoogle.regular};
  font-weight: 500;
  color: ${(props) => props.theme.color.onBackgroundMediumEmphasis};
`;

const StyledButton = styled(CustomButton)`
  ${(props) => props.theme.typo.familyGoogle.regular};
  font-weight: 600;
  box-shadow: 0px 4px 10px rgba(181, 65, 0, 0.5);
`;

const BrowseMoreButton: FC<BrowseMoreButtonProps> = ({
  onBrowseMoreClick,
  isLoading,
  hasBrowseMoreButton,
  currentShowingItemsCount,
  total,
  showLoadingSpinner = true,
}) => {
  const { t } = useTranslation();

  const content = {
    btnText: t('shared.browseMore.btnText'),
    allText: t('shared.browseMore.allText'),
    labelText: (showLabel: string | number, totalLabel: string | number) =>
      t('shared.browseMore.labelText', { showing: showLabel, total: totalLabel }),
  };

  if (isLoading && showLoadingSpinner) {
    return (
      <StyledSpinWrapper>
        <Spin />
      </StyledSpinWrapper>
    );
  }

  return (
    <Container>
      <TextContainer>{content.labelText(currentShowingItemsCount, total)}</TextContainer>
      {hasBrowseMoreButton ? (
        <StyledButton onClick={onBrowseMoreClick}>{content.btnText}</StyledButton>
      ) : (
        <TextContainer>{content.allText}</TextContainer>
      )}
    </Container>
  );
};

export default BrowseMoreButton;

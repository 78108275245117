import React, { FC } from 'react';
import i18next from 'i18next';
import styled, { css } from 'styled-components';
import { Trans, useTranslation } from 'react-i18next';

import useMobileView from '@design-system/hooks/useMobileView';
import type {
  BackgroundImages,
  CarroCertifiedCardProps,
} from '@source/components/CarroCertifiedCard/CarroCertifiedCardProps';
import useRegionConfig from '@source/hooks/useRegionConfig';

const StyledWrapper = styled.div<BackgroundImages>`
  max-width: 100% !important;
  border-radius: 12px;
  padding: 24px;
  height: auto;
  position: relative;
  cursor: pointer;
  ${(props) => props.theme.typo.familyGoogle.regular};
  background-color: ${(props) => props.theme.color.primaryBase};

  /* background-image: url(${(props) => props.bgImage375}); */
  background-repeat: no-repeat;
  background-size: cover;

  @media screen and (min-width: 576px) {
    /* background-image: url(${(props) => props.bgImage576}); */
  }

  @media screen and (min-width: 768px) {
    /* background-image: url(${(props) => props.bgImage768}); */
  }

  @media screen and (min-width: 992px) {
    /* background-image: url(${(props) => props.bgImage992}); */
  }

  @media screen and (min-width: 1280px) {
    width: 1196px;
    padding: 16px 40px;
    /* background-image: url(${(props) => props.bgImage1280}); */
  }

  @media screen and (min-width: 1920px) {
    width: 1600px;
    /* background-image: url(${(props) => props.bgImage1280}); */
  }
`;

const StyledDesktop = styled.div<{ lang?: string }>`
  width: 100%;
  color: ${(props) => props.theme.color.onPrimary};

  .item-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: pre-line;
  }

  .item-title,
  .item {
    flex: 1;
  }

  .item-text {
    height: 40px;
    max-height: 60px;
    color: ${(props) => props.theme.color.background};
    font-size: 13px;
    font-weight: 400;
  }

  .item-title {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
  }

  .item-title-line {
    width: 100%;
    height: 3px;
    background: linear-gradient(0deg, rgba(255, 252, 252, 0.2), rgba(255, 252, 252, 0.2)), #fffcfc;
    flex: auto;
  }

  .item {
    text-align: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
  }

  .item-icon {
    margin-bottom: 14px;
  }

  @media screen and (min-width: 1280px) {
    ${(props) =>
      props.lang === 'ms' &&
      css`
        .item-text {
          height: 60px;
        }
      `};

    .item-icon {
      margin-bottom: 17px;
    }
  }

  @media screen and (max-width: 1279px) {
    .item-wrapper {
      align-items: flex-start;
    }

    .item-title {
      flex-direction: row;
      margin-bottom: 28px;
    }

    .item-title .item-title-line:first-child {
      margin-right: 8px;
    }

    .item-title .item-title-line:last-child {
      margin-left: 8px;
    }

    .item {
      font-size: 11px;
      line-height: 140%;
    }

    .item-icon {
      width: 59px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  @media screen and (max-width: 992px) {
    .item {
      font-size: 10px;
    }
  }
`;

const TitleText = styled.h2`
  color: ${(props) => props.theme.color.onPrimary};
  font-size: 28px;
  line-height: 28px;
  display: flex;
  gap: 5px;
  flex: 1 0 auto;
  letter-spacing: -0.338px;
  margin: 0;
  font-weight: 400;

  & > span:first-child {
    display: inline-block;
    font-weight: 600;
    ${(props) => props.theme.typo.familyGoogle.semiBold};
  }

  & > span:nth-child(2) {
    width: max-content;
    font-weight: 400;
  }

  div {
    display: inline-block;
  }

  & > div:first-child {
    flex: 1 0 auto;
  }

  .bold {
    font-weight: 600;
    ${(props) => props.theme.typo.familyGoogle.semiBold};
  }

  @media screen and (min-width: 768px) {
    margin-bottom: 0px;
    justify-content: center;
    width: max-content;

    span {
      display: unset;
    }
  }

  @media screen and (min-width: 1280px) {
    flex-direction: column;
    font-size: 32px;
    line-height: 32px;
    width: 100%;
    margin-bottom: 12px;

    & > span:first-child {
      ${(props) => props.theme.typo.familyGoogle.semiBold};
    }

    span {
      display: block;
    }
  }
`;

const StyledMobile = styled.div`
  color: ${(props) => props.theme.color.onPrimary};

  .item-title-text {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 5px;
    margin-bottom: 24px;

    & > span:nth-child(2) {
      width: max-content;
    }
  }

  .item-title-line {
    width: 100%;
    height: 3px;
    margin-left: 8px;
    background: linear-gradient(0deg, rgba(255, 252, 252, 0.2), rgba(255, 252, 252, 0.2)), #fffcfc;
  }

  .item-wrapper {
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    padding-inline-start: 0px;
    margin-bottom: 0;

    li {
      display: flex;
      & > div {
        padding-right: 12px;

        svg {
          width: 20px;
          height: 20px;
        }
      }
    }

    li:nth-child(even) {
      margin: 12px 0;
    }
  }
`;

const StyledLinkWrapper = styled.a`
  text-decoration: none;
`;

const CarroCertifiedCard: FC<CarroCertifiedCardProps> = ({ content, href }) => {
  const isMobileScreenMaxWidth = useMobileView(768, true);
  const isLargeScreenMaxWidth = useMobileView(1280, true);

  const { t } = useTranslation();

  const {
    countryConfig: { country },
  } = useRegionConfig();

  const { isTitleLine, body, backgroundImages } = content || {};

  const { bgImage1280, bgImage992, bgImage768, bgImage576, bgImage375 } = backgroundImages || {};

  const renderTitle = () => (
    <TitleText>
      {country === 'my' ? (
        <Trans defaults={t('shared.certifiedModal.my.title')} components={{ div: <div /> }} />
      ) : (
        <Trans defaults={t('shared.certified_section.certified')} components={{ span: <span /> }} />
      )}
    </TitleText>
  );

  const renderDesktop = () => (
    <StyledDesktop lang={i18next.language}>
      {isLargeScreenMaxWidth && (
        <div className="item-title">
          {isTitleLine && <div className="item-title-line" />}
          {renderTitle()}
          {isTitleLine && <div className="item-title-line" />}
        </div>
      )}
      <div className="item-wrapper">
        {!isLargeScreenMaxWidth && (
          <div className="item-title">
            {renderTitle()}
            {isTitleLine && <div className="item-title-line" />}
          </div>
        )}

        {body?.map((item) => (
          <div className="item" key={item?.key}>
            <div className="item-icon">
              <img src={item?.icon?.desktop} alt="carro certified" width={66} height={66} />
            </div>
            <h3 className="item-text">{t(item?.text?.desktop)}</h3>
          </div>
        ))}
      </div>
    </StyledDesktop>
  );

  const renderMobile = () => (
    <StyledMobile>
      <div className="item-title-text">
        {renderTitle()}
        {isTitleLine && <div className="item-title-line" />}
      </div>

      <ul className="item-wrapper">
        {body.map((item) => (
          <li key={item?.key}>
            <div>{item?.icon?.mobile}</div>
            <div>{t(item?.text?.mobile)}</div>
          </li>
        ))}
      </ul>
    </StyledMobile>
  );

  return (
    <StyledLinkWrapper href={href}>
      <StyledWrapper
        bgImage1280={bgImage1280}
        bgImage992={bgImage992}
        bgImage768={bgImage768}
        bgImage576={bgImage576}
        bgImage375={bgImage375}
      >
        {isMobileScreenMaxWidth ? renderMobile() : renderDesktop()}
      </StyledWrapper>
    </StyledLinkWrapper>
  );
};

export default CarroCertifiedCard;

/* eslint-disable react/require-default-props */
import { Popover } from 'antd';
import dynamic from 'next/dynamic';
import React, { PropsWithChildren, useState } from 'react';
import { css } from 'styled-components';

import type { ContentProps, SettingProps } from '@design-system/components/FiltersV1/FilterProps';
import FilterCustomButton from '@design-system/components/FiltersV1/FilterCustomButton';

import { getSelectedFiltersCount } from '@design-system/utils/filters';

const FilterForm = dynamic(() => import('@design-system/components/FiltersV1/FilterForm'), { ssr: false });

const CustomButtonStyles = css`
  margin: 0 4px;
`;

interface FilterPopoverTypeProps extends PropsWithChildren {
  content?: ContentProps;
  setting?: SettingProps;
  initSettings?: SettingProps;
  needSettingButton?: boolean;
  disableSelectedFilterBtn?: boolean;
  onFormSave?: (
    payloads: Record<string, SettingProps>,
    isSaveAction: boolean,
    type: string,
    isFetchHitCount: boolean,
  ) => void;
}

const FilterPopoverType = ({ content, setting, initSettings, children, onFormSave }: FilterPopoverTypeProps) => {
  const { isBooleanFilter, hideInFilters } = setting || {};
  const [visible, setVisible] = useState(false);

  if (hideInFilters) return null;

  const handleOnFilterSave = ({
    payloads,
    isSaveAction = true,
  }: {
    payloads: Record<string, SettingProps>;
    isSaveAction?: boolean;
  }) => {
    if (!isBooleanFilter) {
      // force close popover on save
      setVisible(false);
    }

    setTimeout(() => {
      if (onFormSave) onFormSave(payloads, isSaveAction, 'popover', false);
    }, 150);
  };

  const handleOnVisibleChanged = (v: boolean) => {
    setVisible(v);
  };

  const renderFilterForm = () => {
    const otherProps = isBooleanFilter
      ? {
          isBooleanFilter: true,
          settingHeader: setting?.header,
          requireActiveState: true,
        }
      : {
          width: setting?.width,
        };

    return (
      <FilterForm
        content={content}
        filters={[{ ...setting }] || []}
        initSettings={[{ ...initSettings }] || []}
        settingName={setting?.name}
        onFormSave={handleOnFilterSave}
        // Pass other props base on the isBooleanFilter value
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...otherProps}
      />
    );
  };

  if (isBooleanFilter) {
    // no need for popover
    return renderFilterForm();
  }

  // const isFilterHasOptions = setting?.filters?.some((filter) => filter?.options?.length > 0) || false;
  // const isFilterHasFacetStats = setting?.filters?.some((filter) => filter?.facetStats) || false;

  // if (setting?.algoliaFetching && !isFilterHasOptions && !isFilterHasFacetStats) {
  //   return null;
  // }

  // const isMultiSelect = setting?.filters?.some((filter) => hasMultiSelectValues(filter));

  const selectedFiltersCount = getSelectedFiltersCount(Array(setting || []) as SettingProps[]);

  const label = selectedFiltersCount > 1 ? `${children} • ${selectedFiltersCount}` : children;

  return (
    <Popover
      overlayClassName="filter-popover"
      overlayInnerStyle={{ borderRadius: '30px', minWidth: '380px' }}
      content={() => renderFilterForm()}
      placement="bottomLeft"
      onVisibleChange={handleOnVisibleChanged}
      destroyTooltipOnHide={setting?.name !== 'make_model'}
      visible={visible}
      trigger={!isBooleanFilter ? 'click' : undefined}
    >
      <FilterCustomButton isActive={selectedFiltersCount > 0} customStyles={CustomButtonStyles}>
        {label}
      </FilterCustomButton>
    </Popover>
  );
};

export default React.memo(FilterPopoverType);

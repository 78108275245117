import { FC, useState } from 'react';
import styled, { css, CSSObject } from 'styled-components';
import i18next from 'i18next';
import Image from 'next/image';
import sortBy from 'lodash/sortBy';
import { Breakpoint } from 'antd/lib/_util/responsiveObserve';
import { Modal } from 'antd';

import { Button } from '@design-system/components';
import { THEME } from '@source/constants/theme';
import useRegionConfig from '@source/hooks/useRegionConfig';
import Carousel, { CarouselItem } from '@source/pages/Home/components/Carousel/Carousel';
import {
  BannerJSON,
  BannerJSONButton,
  BannerJSONContent,
  BannerJSONStyle,
  BannerJSONButtonProps,
  BannerJsonModal,
  BannerJSONCounterProps,
} from '@source/pages/Home/components/BannersCarousel/types';
import CarroCertifiedContent from '@source/pages/Home/components/CarroCertified/CarroCertifiedContent';
import { StringToJSX } from '@source/pages/Home/components/BannersCarousel/StringToJSX';
import BannerModal from '@source/components/BannerModal/BannerModal';
import StyledContainer from '@design-system/styled/Container';
import TimeCounter from './TimeCounter';

const StyledBannerHeight = styled.div`
  --banner-height-default: 180px;
  --banner-height-576: 277px;
  --banner-height-768: 353px;
  --banner-height-992: 414px;
  --banner-height-1280: 320px;
  --banner-height-1920: 320px;
`;

const StyledBannerContainer = styled(StyledContainer)`
  @media only screen and (max-width: 767px) {
    padding-left: 0;
    padding-right: 0;
  }
`;

const StyledCarouselWrapper = styled(StyledBannerHeight)<{ bannerLength: number }>`
  --slick-dots-container-height: ${(props) => (props.bannerLength <= 1 ? '0px' : '26px')};
  --slick-dots-width: 10px;
  --slick-dots-height: 10px;
  --sick-dots-gap: 12px;
  --slick-dots-active-width: 22px;
  --slick-dots-active-border-radius: 20px;

  width: 100%;

  .button-wrapper {
    display: flex;
  }

  .slick-dots {
    position: relative;
    bottom: initial;
    transition: all 0.3s;

    li,
    button,
    button::before {
      width: var(--slick-dots-width);
      height: var(--slick-dots-height);
      content: '';
      border-radius: 100%;
      background-color: #dfdfdf;
    }

    li {
      &:nth-child(even) {
        margin: 0 var(--sick-dots-gap);
      }

      &.slick-active {
        width: var(--slick-dots-active-width);
        border-radius: var(--slick-dots-active-border-radius);
        background-color: ${(props) => props.theme.color.primaryBase};

        button,
        button::before {
          width: var(--slick-dots-active-width);
          border-radius: var(--slick-dots-active-border-radius);
          background-color: ${(props) => props.theme.color.primaryBase};
        }
      }

      button {
        padding: 0;

        &::before {
          opacity: 1;
        }
      }
    }
  }
`;

const StyledSafeZoneContentHeight = styled.div`
  --safezone-width-default: 352px;
  --safezone-width-576: 480px;
  --safezone-width-768: 696px;
  --safezone-width-992: 780px;
  --safezone-width-1280: 1148px;
  --safezone-width-1920: 1148px;

  --safezone-height-default: 155px;
  --safezone-height-576: 180px;
  --safezone-height-768: 190px;
  --safezone-height-992: 210px;
  --safezone-height-1280: 225px;
  --safezone-height-1920: 225px;
`;

const StyledContentContainer = styled(StyledSafeZoneContentHeight)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: var(--safezone-width-default);
  height: var(--safezone-height-default);
  color: ${(props) => props.color};

  ${(props: { customStyle: CSSObject }) =>
    props.customStyle &&
    css`
      ${props.customStyle}
    `}

  @media screen and (min-width: 576px) {
    width: var(--safezone-width-576);
    height: var(--safezone-height-576);
  }

  @media screen and (min-width: 768px) {
    width: var(--safezone-width-768);
    height: var(--safezone-height-768);
  }

  @media screen and (min-width: 992px) {
    width: var(--safezone-width-992);
    height: var(--safezone-height-992);
  }

  @media screen and (min-width: 1280px) {
    width: var(--safezone-width-1280);
    height: var(--safezone-height-1280);
  }

  @media screen and (min-width: 1920px) {
    width: var(--safezone-width-1920);
    height: var(--safezone-height-1920);
  }
`;

const StyledCarouselListWrapper = styled(StyledBannerHeight)`
  position: relative;
`;

const StyledImageWrapper = styled(StyledBannerHeight)<{ isClickable: boolean }>`
  position: relative;
  min-height: 180px;
  aspect-ratio: 48/23;
  overflow: hidden;

  cursor: ${(props) => (props.isClickable ? 'pointer' : 'default')};

  & .banner-image {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  & .tablet {
    display: none;
  }

  & .desktop {
    display: none;
  }

  @media screen and (min-width: 576px) {
    aspect-ratio: auto;
    height: 277px;
    margin-top: 32px;
    border-radius: 12px;
    aspect-ratio: auto;

    & .mobile {
      display: none;
    }
    & .tablet {
      display: block;
    }
  }

  @media screen and (min-width: 768px) {
    height: 353px;
  }

  @media screen and (min-width: 922px) {
    height: 414px;
  }

  @media screen and (min-width: 1280px) {
    height: 320px;
    & .tablet {
      display: none;
    }

    & .desktop {
      display: block;
    }
  }
`;

const StyledCTAButtonsWrapper = styled.div`
  ${(props: { customStyle: CSSObject }) =>
    props.customStyle &&
    css`
      ${props.customStyle}
    `}
`;

const StyledCTAButton = styled(Button)`
  background-color: ${(props) => props.theme.color.primaryBase};
  color: ${(props) => props.theme.color.onPrimary};
  box-shadow: 0px 4px 10px 0px #b5410080;

  &.ant-btn {
    font-size: 12px;
    ${(props) => props.theme.typo.familyGoogle.semiBold};
    font-weight: 600;
    line-height: 140%;
    padding: 0 24px;
    height: 40px;
    color: ${(props) => props.theme.color.onPrimary};
    border-radius: 8px;
  }

  &.tnc-button {
    font-size: 10px;
    box-shadow: none;
    background-color: transparent !important;
    border: none;
    color: inherit;
    text-decoration: underline !important;
    text-transform: capitalize !important;

    span {
      text-decoration: inherit;
      text-transform: inherit;
    }
  }

  ${(props: { customStyle: CSSObject }) =>
    props.customStyle &&
    css`
      ${props.customStyle}
    `}

  @media screen and (min-width: 768px) {
    &.ant-btn {
      font-size: 16px;
      padding: 0 16px;
      height: 48px;
    }

    &.tnc-button {
      font-size: 12px;
    }
  }

  @media screen and (min-width: 1280px) {
    &.tnc-button {
      font-size: 14px;
    }
  }
`;

const StyledContentWrapper = styled.div`
  position: absolute;

  ${(props: { customStyle: CSSObject }) =>
    props.customStyle &&
    css`
      ${props.customStyle}
    `}
`;

const StyledHeaderText = styled.div`
  font-size: 20px;
  line-height: 25px;
  letter-spacing: -0.338235px;
  margin-bottom: 10px;
  ${(props) => props.theme.typo.familyGoogle.bold};

  @media screen and (min-width: 768px) {
    font-size: 22px;
    line-height: 27px;
  }

  @media screen and (min-width: 992px) {
    font-size: 24px;
    line-height: 30px;
  }

  @media screen and (min-width: 1280px) {
    font-size: 28px;
  }

  @media screen and (min-width: 1920px) {
    font-size: 32px;
    line-height: 36px;
  }

  ${(props: { customStyle: CSSObject }) =>
    props.customStyle &&
    css`
      ${props.customStyle}
    `}
`;

const StyledSecondaryText = styled.div`
  font-size: 11px;
  line-height: 14px;
  font-weight: 400;
  letter-spacing: -0.25px;
  margin-bottom: 14px;
  ${(props) => props.theme.typo.familyGoogle.regular};

  ${(props: { customStyle: CSSObject }) =>
    props.customStyle &&
    css`
      ${props.customStyle}
    `}

  @media screen and (min-width: 576px) {
    font-size: 10px;
    line-height: 14px;
  }

  @media screen and (min-width: 768px) {
    font-size: 12px;
    line-height: 14px;
  }

  @media screen and (min-width: 992px) {
    font-size: 14px;
    line-height: 20px;
    ul > li:nth-child(even) {
      margin: 8px 0;
    }
  }

  @media screen and (min-width: 1280px) {
    font-size: 16px;
  }
`;

export const transformStyledMediaQuery = (style?: BannerJSONStyle): CSSObject => {
  if (!style || Object.keys(style).length === 0) return {};

  const result = Object.entries(style).reduce((transformed, original) => {
    const [breakpoint, styleValue] = original;
    let customTransformed = transformed;
    if (breakpoint === 'default') {
      customTransformed = { ...transformed, ...styleValue };
    } else {
      customTransformed = {
        ...transformed,
        [`@media${THEME.metrics.mediaQueries[breakpoint as Breakpoint]}`]: styleValue,
      };
    }
    return customTransformed;
  }, {});

  return result;
};

export interface BannersCarouselContainerProps {
  banners: BannerJSON[];
  deviceHint: 'desktop' | 'tablet' | 'mobile';
  // eslint-disable-next-line react/require-default-props
  onImageLoaded?: () => void;
}

const BannersCarouselContainer: FC<BannersCarouselContainerProps> = ({
  banners,
  deviceHint,
  onImageLoaded,
}: BannersCarouselContainerProps) => {
  const bannerList = sortBy(banners, 'order');

  const [isShowModal, setIsShowModal] = useState<boolean>(false);
  const [currentModal, setCurrentModal] = useState<BannerJsonModal | null>(null);
  const { countryConfig } = useRegionConfig();
  const countryCode = countryConfig?.country;

  const handleButtonClick = ({
    url,
    isNewTab,
    isModal,
    modal,
  }: {
    url?: string;
    isNewTab?: boolean;
    isModal?: boolean;
    modal?: BannerJsonModal | null;
  }) => {
    if (isModal) {
      setIsShowModal(true);
      setCurrentModal(modal || null);
    }
    if (isNewTab && url) {
      return window.open(encodeURI(url));
    }
    if (url) {
      window.location.href = encodeURI(url);
    }
    return null;
  };

  const handleCloseModal = () => {
    setIsShowModal(false);
  };

  const renderTitle = ({ text, style }: BannerJSONButtonProps) => (
    <StyledHeaderText customStyle={transformStyledMediaQuery(style)}>
      {typeof text === 'string' ? <StringToJSX domString={text || ''} /> : text}
    </StyledHeaderText>
  );

  const renderDescription = ({ text, style }: BannerJSONButtonProps) => (
    <StyledSecondaryText customStyle={transformStyledMediaQuery(style)}>
      {typeof text === 'string' ? <StringToJSX domString={text || ''} /> : text}
    </StyledSecondaryText>
  );

  const renderCustomModal = (modal: BannerJsonModal) => (
    <BannerModal banner={modal} isShowModal={isShowModal} onClose={handleCloseModal} onOk={handleCloseModal} />
  );

  const renderButtons = ({ buttons, style }: BannerJSONButton) => (
    <StyledCTAButtonsWrapper customStyle={transformStyledMediaQuery(style)}>
      {buttons &&
        buttons.map(({ text, style: buttonStyle, url, href, isNewTab, modal, isTnc }: BannerJSONButtonProps) => (
          <StyledCTAButton
            className={isTnc ? 'tnc-button' : ''}
            href={href}
            customStyle={transformStyledMediaQuery(buttonStyle)}
            onClick={() => handleButtonClick({ url, isNewTab, isModal: !!modal, modal })}
          >
            {typeof text === 'string' ? <StringToJSX domString={text} /> : text}
          </StyledCTAButton>
        ))}
    </StyledCTAButtonsWrapper>
  );

  const renderCounter = (counter: BannerJSONCounterProps) => (
    <TimeCounter
      counterBg={counter.background_url}
      endTime={counter.end_time}
      customStyle={transformStyledMediaQuery(counter.style)}
    />
  );

  const renderContent = ({ title, desc, button, counter, wrapperStyle }: BannerJSONContent) => (
    <StyledContentWrapper customStyle={transformStyledMediaQuery(wrapperStyle)}>
      {title && renderTitle(title)}
      {desc && renderDescription(desc)}
      {button && renderButtons(button)}
      {counter && renderCounter(counter)}
    </StyledContentWrapper>
  );

  const renderImageBanner = (banner: BannerJSON) => {
    const { images, contents, url, modal } = banner;

    const mobileImage = images.xs || images.sm;
    const tabletImage = images.md || images.lg;
    const desktopImage = images.xl || images.xxl;

    return (
      <StyledImageWrapper isClickable={!!modal || !!url}>
        <div className="mobile banner-image">
          <Image
            alt="banner"
            src={mobileImage}
            data-test-id={mobileImage}
            layout="fill"
            objectFit="cover"
            placeholder="blur"
            blurDataURL={`${mobileImage}?tr=w-10`}
            priority={deviceHint === 'mobile'}
            onLoadingComplete={onImageLoaded}
          />
        </div>
        <div className="tablet banner-image">
          <Image
            alt="banner"
            src={tabletImage}
            data-test-id={tabletImage}
            layout="fill"
            objectFit="cover"
            placeholder="blur"
            blurDataURL={`${tabletImage}?tr=w-10`}
            priority={deviceHint === 'tablet'}
            onLoadingComplete={onImageLoaded}
          />
        </div>
        <div className="desktop banner-image">
          <Image
            alt="banner"
            src={desktopImage}
            data-test-id={desktopImage}
            layout="fill"
            objectFit="cover"
            placeholder="blur"
            blurDataURL={`${desktopImage}?tr=w-10`}
            priority={deviceHint === 'desktop'}
            onLoadingComplete={onImageLoaded}
          />
        </div>
        {contents &&
          contents.map(({ content, wrapperStyle }) => (
            <StyledContentContainer customStyle={transformStyledMediaQuery(wrapperStyle)}>
              {renderContent(content)}
            </StyledContentContainer>
          ))}
      </StyledImageWrapper>
    );
  };

  const renderBanner = (banner: BannerJSON) => renderImageBanner(banner);

  const renderBannerModal = () => {
    let modal = null;
    if (currentModal?.id === 'CarroCertified') {
      modal = (
        <Modal
          className="ccpo-popover"
          visible={isShowModal}
          bodyStyle={{ padding: 0 }}
          maskClosable
          closable={false}
          onCancel={handleCloseModal}
          footer={null}
          width="auto"
          centered
        >
          <CarroCertifiedContent />
        </Modal>
      );
    } else if (currentModal) {
      modal = renderCustomModal(currentModal);
    }

    return modal;
  };

  return (
    <>
      <StyledBannerContainer>
        <StyledCarouselWrapper className={bannerList.length < 1 ? 'hidden' : ''} bannerLength={bannerList.length}>
          <StyledCarouselListWrapper>
            <Carousel>
              {bannerList.map((banner, index) => {
                const { url, modal, isInternalLink, isNewTab } = banner;

                const configUrl = isInternalLink ? `/${countryCode}/${i18next.language}${url}` : url;

                return (
                  <CarouselItem
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    onClickCapture={() => handleButtonClick({ url: configUrl, isNewTab, isModal: !!modal, modal })}
                  >
                    {renderBanner(banner)}
                  </CarouselItem>
                );
              })}
            </Carousel>
          </StyledCarouselListWrapper>
        </StyledCarouselWrapper>
      </StyledBannerContainer>
      {currentModal && renderBannerModal()}
    </>
  );
};

export default BannersCarouselContainer;

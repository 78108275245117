/* eslint-disable no-nested-ternary */
import { GetServerSideProps } from 'next';

import Home from '@source/pages/Home';
import { getStrapiBanners } from '@source/services/Strapi';
import { TRegionKey, TLanguageKey, IRegionOptions } from '@source/interface';
import { BannerJSON } from '@source/pages/Home/components/BannersCarousel/types';
import { CARRO_MOBILE_APP_KEY, COOKIE_KEYS } from '@source/constants/common';
import {
  SUPPORTED_COUNTRIES,
  AVAILABLE_COUNTRIES,
  DEFAULT_LANGUAGE_MAP_BY_COUNTRY,
  SUPPORTED_LANGUAGES,
} from '@source/constants/config';
import useragent from 'express-useragent';
import { logSentryError } from '@source/utils/utils';
import i18n from '@source/i18next';
import homeEn from '@source/pages/Home/lang/en.json';
import homeId from '@source/pages/Home/lang/id.json';
import homeMy from '@source/pages/Home/lang/my.json';
import homeTh from '@source/pages/Home/lang/th.json';
import homeJa from '@source/pages/Home/lang/ja.json';
import homeZhSg from '@source/pages/Home/lang/zhSg.json';
import homeMsSg from '@source/pages/Home/lang/msSg.json';
import homeZhHanT from '@source/pages/Home/lang/zhHanT.json';
import homeZhHanS from '@source/pages/Home/lang/zhHanS.json';

i18n.addResourceBundle(SUPPORTED_LANGUAGES.EN.code, 'translation', homeEn);
i18n.addResourceBundle(SUPPORTED_LANGUAGES.ID.code, 'translation', homeId);
i18n.addResourceBundle(SUPPORTED_LANGUAGES.MY.code, 'translation', homeMy);
i18n.addResourceBundle(SUPPORTED_LANGUAGES.TH.code, 'translation', homeTh);
i18n.addResourceBundle(SUPPORTED_LANGUAGES.JA.code, 'translation', homeJa);
i18n.addResourceBundle(SUPPORTED_LANGUAGES['ZH-SG'].code, 'translation', homeZhSg);
i18n.addResourceBundle(SUPPORTED_LANGUAGES['ZH-MY'].code, 'translation', homeZhSg);
i18n.addResourceBundle(SUPPORTED_LANGUAGES['MS-SG'].code, 'translation', homeMsSg);
i18n.addResourceBundle(SUPPORTED_LANGUAGES['ZH-HANT-HK'].code, 'translation', homeZhHanT);
i18n.addResourceBundle(SUPPORTED_LANGUAGES['ZH-HANS-HK'].code, 'translation', homeZhHanS);

// CRB-134 - TODO: apply for the other pages
const getCurrentRegion = ({
  urlRegion,
  cookieRegion,
  cloudflareCountry,
}: IRegionOptions & { cloudflareCountry: TRegionKey }) => {
  const isDefaultRegion = !urlRegion || urlRegion === 'default';
  let currentRegion: TRegionKey | null = isDefaultRegion ? cookieRegion : urlRegion;
  if (isDefaultRegion && !cookieRegion) {
    const cfCountry = (cloudflareCountry?.toLowerCase() as TRegionKey) || null;
    currentRegion = cfCountry && AVAILABLE_COUNTRIES.includes(cfCountry) ? cfCountry : null;
  }

  // able to detect user region based on url/cookie/cloudflare header
  const isDetected = currentRegion && AVAILABLE_COUNTRIES.includes(currentRegion as TRegionKey);

  currentRegion = isDetected ? currentRegion : SUPPORTED_COUNTRIES.SG.code;

  return {
    currentRegion,
    isDetected,
  };
};

export default Home;

export const getServerSideProps: GetServerSideProps = async ({ locale, req, query }) => {
  let banners = [];
  const isFromMobileApp = req.cookies?.[CARRO_MOBILE_APP_KEY] === 'true';
  const cookieRegion = req.cookies?.[COOKIE_KEYS.STORED_REGION];
  const cookieLang = req.cookies?.[`${COOKIE_KEYS.STORED_LANGUAGE_BY_REGION}_${cookieRegion?.toUpperCase()}`];
  const defaultLang = DEFAULT_LANGUAGE_MAP_BY_COUNTRY?.[locale as TRegionKey]?.code;
  const lang = query?.lang || cookieLang || defaultLang || SUPPORTED_LANGUAGES.EN.code;

  const { currentRegion, isDetected } = getCurrentRegion({
    urlRegion: locale as TRegionKey,
    cookieRegion: cookieRegion as TRegionKey,
    cloudflareCountry: req.headers?.['cf-ipcountry'] as TRegionKey,
  });

  // if we are able to detect user region based on url/cookie/cloudflare header,
  // then fetch banners from strapi,
  // otherwise we will not show banners to the user
  if (isDetected) {
    try {
      const serverBanners = await getStrapiBanners(currentRegion as TRegionKey, {
        locale: lang as TLanguageKey,
        page: 'home_page',
      }).catch(() => []);

      banners = serverBanners.map((banner: { [key: string]: BannerJSON }) => banner.json);
    } catch (error) {
      logSentryError({
        region: currentRegion as TRegionKey,
        errorMessage: `Failed to fetch strapi banners`,
        data: error,
        req,
      });
    }
  }

  const UA = useragent.parse(req.headers['user-agent'] || '');

  return {
    props: {
      region: currentRegion,
      banners,
      isFromMobileApp,
      deviceHint: UA.isDesktop ? 'desktop' : UA.isTablet ? 'tablet' : 'mobile',
      lang,
    },
  };
};
